body {
  min-height: 100vh;
  min-width: min-content;
  font-family: CalibriWeb, Arial, sans-serif;
  background: url(./assets/water_bg.png);
  background-size: cover; 
}

button {
  font-family: inherit;
}
